@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback");

@tailwind base;
@tailwind components;

@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/pagination";
@import "swiper/css/scrollbar";

@layer components {
  @keyframes slideInFromLeft {
    0% {
      width: 0%;
    }
    70% {
      width: 120%;
    }
    100% {
      width: 100%;
    }
  }

  @keyframes fadeInHighlight {
    0% {
      transform: -100%;
    }
    100% {
      border-bottom-width: 2px;
    }
  }

  .typo-marker {
    position: relative;
    z-index: 2;
    white-space: nowrap;
  }

  .typo-marker:after {
    background-color: #ff5959;
    opacity: 65%;
    content: " ";
    height: 40%;
    position: absolute;
    overflow: hidden;
    left: 0;
    margin-left: 0;
    top: 55%;
    z-index: -1;
    width: 100%;
    animation: 0.3s ease-in-out slideInFromLeft;
  }

  .seo-marker {
    position: relative;
    z-index: 2;
    white-space: nowrap;
  }

  .seo-marker:after {
    background-color: #59afff;
    opacity: 65%;
    content: " ";
    height: 20%;
    position: absolute;
    overflow: hidden;
    left: 0;
    bottom: 0%;
    z-index: -1;
    margin-left: 2.5%;
    margin-right: 2.5%;
    width: 95%;
    animation: 0.3s ease-in-out slideInFromLeft;
  }

  .autocomplete-marker {
    @apply text-black/30;
  }
}

// Additional styles
@import "additional-styles/utility-patterns.scss";
@import "additional-styles/range-slider.scss";
@import "additional-styles/toggle-switch.scss";
@import "additional-styles/flatpickr.scss";
@import "additional-styles/theme.scss";
@import "additional-styles/quilljs.scss";
@import "react-toastify/dist/ReactToastify.css";

.Toastify__toast-container {
  top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

@tailwind utilities;

@layer utilities {
  .pb-safe {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

// Swiper classes
.swiper-button-prev {
  background: white;
  opacity: 0.5;
  width: 50px;
  padding: 25px;
  border-radius: 100%;
  box-shadow: 2px 2px 10px black;
  margin-left: -5px;
}

.swiper-button-prev:hover {
  opacity: 1;
}

.swiper-button-prev::after {
  color: black;
  font-size: 20px;
  font-weight: bold;
}

.swiper-button-next {
  background: white;
  opacity: 0.5;
  width: 50px;
  padding: 25px;
  border-radius: 100%;
  box-shadow: 2px 2px 10px black;
  margin-right: -5px;
}

.swiper-button-next:hover {
  opacity: 1;
}

.swiper-button-next::after {
  color: black;
  font-size: 20px;
  font-weight: bold;
}
